export interface GoogleAnalyticsEvent
{
	action: string,
	category: string,
	label: string
	value: number
}

/**
 * Send Google Analytics page view
 * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 */
export const sendPageView = (url: string): void =>
{
	// @ts-ignore
	window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {page_path: url});
}

/**
 * Send Google Analytics event
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
export const sendEvent = ({action, category, label, value}: GoogleAnalyticsEvent): void =>
{
	// @ts-ignore
	window.gtag(
		'event',
		action,
		{
			event_category: category,
			event_label:    label,
			value:          value,
		}
	)
}
