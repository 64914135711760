import { getColor } from '@/helpers/theme';

const THEME = {
	baseStyle: {},
	variants:  {
		inlineBlock:            {
			display: 'inline-block',
		},
		cardSolid:              ({colorMode}) => ({
			bg:           getColor(colorMode, ['background', '80'], ['background', '5']),
			boxShadow:    '0 2px 4px 0 rgba(0,0,0,0.25);',
			marginBottom: '1rem',
			marginTop:    '1rem',
			padding:      '1rem',
			position:     'relative',
			width:        '100%',
		}),
		cardTransparent:        ({colorMode}) => ({
			bg:           getColor(colorMode, ['background', 'base'], 'white'),
			boxShadow:    '0 2px 4px 0 rgba(0,0,0,0.25);',
			border:       '1px solid',
			borderColor:  getColor(colorMode, ['background', '80'], ['background', '5']),
			marginBottom: '1rem',
			marginTop:    '1rem',
			padding:      '1rem',
			position:     'relative',
		}),
		copyrightOuter:         ({colorMode}) => ({
			boxShadow: '0 -3px 4px 0 rgba(0,0,0,0.25)',
			py:        '1rem',
			textAlign: 'center',
		}),
		footerOuter:            ({colorMode}) => ({
			bg:            getColor(colorMode, ['background', '80'], ['background', '5']),
			boxShadow:     '0 -3px 4px 0 rgba(0,0,0,0.25)',
			marginTop:     '6.25rem',
			paddingBottom: '1.75rem',
			paddingTop:    '2rem',
			textAlign:     'center',
		}),
		navigationPrimaryOuter: ({colorMode}) => ({
			bg: getColor(colorMode, ['background', '80'], ['background', '5']),
			py: '0.5rem',
		}),
	},
};

export default THEME;
