import axios from 'axios';

type GetType = {
	config?: object,
	onError?: Function,
	onSuccess?: Function,
	url: string,
}

type PostType = {
	config?: object,
	data?: object,
	onError?: Function,
	onSuccess?: Function,
	url: string,
}

/**
 * Make a GET request.
 */
export const get = async ({config = {}, onError, onSuccess, url}: GetType): Promise<any> =>
{
	const response = await axios.get(url, config)
		.then(async response =>
		{
			return onSuccess ? await onSuccess(response) : response;
		})
		.catch(async error =>
		{
			if (onError)
			{
				return await onError(error);
			}
			else
			{
				throw Error;
			}
		});
	
	return response;
}

/**
 * Make a POST request.
 */
export const post = async ({config = {}, data = {}, onError, onSuccess, url}: PostType): Promise<any> =>
{
	const response = await axios.post(url, data, config)
		.then(async response =>
		{
			return onSuccess ? await onSuccess(response) : response;
		})
		.catch(async error =>
		{
			if (onError)
			{
				return await onError(error);
			}
			else
			{
				throw Error;
			}
		});
	
	return response;
}
