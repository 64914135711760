import { getBoolean }              from '@/helpers/boolean';
import {
	BOOLEAN,
	CURRENCY_EUR,
	CURRENCY_GBP,
	CURRENCY_USD,
	HTML,
	JSON_FORMAT,
	NUMBER,
	NUMBER_2DP,
	NUMBER_2DP_THOUSANDS,
	NUMBER_SIMPLIFIED,
	NUMBER_THOUSANDS,
	PERCENT,
	PERCENT_DIVIDE_100,
	PERCENT_NUMBER,
	SQL,
	STRING,
	URL,
	URL_DECODE,
	URL_ENCODE,
}                                  from '@/helpers/enum';
import { formatNumber, getNumber } from '@/helpers/number';
import { formatString, getJson }   from '@/helpers/string';
import isString                    from 'lodash/isString';
import isUndefined                 from 'lodash/isUndefined';

/**
 * Return a formatted input.
 */
export const getFormatted = (input: any, format: string): any =>
{
	let cleanInput = isString(input) ? input.trim() : input;
	
	switch (format)
	{
		case BOOLEAN:
			return getBoolean(cleanInput);
		
		case CURRENCY_GBP:
		case CURRENCY_EUR:
		case CURRENCY_USD:
		case NUMBER_2DP:
		case NUMBER_2DP_THOUSANDS:
		case NUMBER_SIMPLIFIED:
		case NUMBER_THOUSANDS:
		case PERCENT:
		case PERCENT_DIVIDE_100:
			return formatNumber(cleanInput, format);
		
		/**
		case DATE_MOMENT:
			return getMoment(cleanInput);
		
		case DATE_DDMMYYYY_HYPHEN:
		case DATE_DDMMYYYY_SLASH:
		case DATE_MMDDYYYY_HYPHEN:
		case DATE_MMDDYYYY_SLASH:
		case DATE_YYYYMMDD_HYPHEN:
		case DATE_YYYYMMDD_SLASH:
			return formatMoment(cleanInput, format);
		
		case DATE_JS:
			return getMoment(cleanInput)
				.toDate();
		 */
		
		case JSON_FORMAT:
			return getJson(cleanInput);
		
		//case DATE_NUMBER:
		case NUMBER:
		case PERCENT_NUMBER:
			return getNumber(cleanInput);
		
		case HTML:
		case SQL:
		case STRING:
		case URL:
		case URL_DECODE:
		case URL_ENCODE:
			return formatString(cleanInput, format);
		
		default:
			return cleanInput;
	}
}


/**
 * Function to default to a value, whilst filtering through an instance
 */
export const defaultTo = (defaultValue, instance, ...keys): any =>
{
	let found   = true;
	let pointer = instance;
	
	// Instance is undefined
	if (isUndefined(instance))
	{
		found = false;
	}
	
	// Instance exists
	else
	{
		keys.forEach(key =>
		{
			if (found)
			{
				// Sub key doesn't exist
				if (isUndefined(pointer[key]))
				{
					found = false;
				}
				
				// Sub key exists, update pointer
				else
				{
					pointer = pointer[key];
				}
			}
		});
	}
	
	return found ? pointer : defaultValue;
}
