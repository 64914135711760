import { buildAlignment } from '@/helpers/css';
import { Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';

export type RowType = {
	children?: ReactNode,
	hAlign?: undefined | 'start' | 'center' | 'end' | 'around' | 'between' | 'evenly',
	vAlign?: undefined | 'start' | 'center' | 'end',
	[chakraOptions: string]: any,
}

const Row = (props: RowType) =>
{
	const {children, hAlign, vAlign, ...rest} = props;
	
	return (
		<Flex
			align={buildAlignment(vAlign)}
			justify={buildAlignment(hAlign)}
			mx='-0.75rem'
			wrap='wrap'
			{...rest}
		>
			{children}
		</Flex>
	);
}

Row.defaultProps = {
	children: undefined,
	hAlign:   undefined,
	vAlign:   undefined,
}

export default Row;
