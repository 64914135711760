import { CUSTOM, LAST_WEEK, NONE, THIS_WEEK } from '@/helpers/enum';
import format from 'date-fns/format';
import getISODay from 'date-fns/getISODay'
import intervalToDuration from 'date-fns/intervalToDuration'
import isAfter from 'date-fns/isAfter';
import isMonday from 'date-fns/isMonday'
import isSunday from 'date-fns/isSunday'
import isValid from 'date-fns/isValid';
import dateParse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO';
import sub from 'date-fns/sub';
import dateSub from 'date-fns/subDays';


export type TimeframeObjectType = {
	start: number,
	end: number,
	enabled: boolean,
}

/**
 * Shortcut to get today's date.
 * Set to 1am to prevent daylight savings causing issues
 */
export const getToday = (): Date =>
{
	const today = new Date();
	today.setHours(1, 0, 0, 0);
	
	return today;
}

/**
 * Short cut to get today as YYYY-MM-DD format.
 */
export const getTodayString = (): string => getString(getToday());

/**
 * Shortcut to get yesterday's date.
 */
export const getYesterday = (): Date =>
{
	let yesterday = getToday();
	yesterday     = dateSub(yesterday, 1);
	
	return yesterday;
}

/**
 * Short cut to get yesterday as YYYY-MM-DD format.
 */
export const getYesterdayString = (): string => getString(getYesterday());

/**
 * From a given input, move the date back X weeksAgo
 */
export const getWeeksAgo = (input: number, weeksAgo: number = 0): number =>
{
	const date     = parseNumber(input);
	const response = sub(date, {weeks: weeksAgo});
	
	return getNumber(response);
}

/**
 * Shortcut to parse number then format it.
 */
export const formatNumber = (input: number, dateFormat: string): string =>
{
	const date = parseNumber(input);
	return format(date, dateFormat);
}

/**
 * Convert a value in YYYYMMDD format into a Date.
 */
export const parseNumber = (input: number | string): Date =>
{
	const string = input.toString() + ' 02:00:00';
	return dateParse(string, 'yyyyMMdd HH:mm:ss', new Date());
}

/**
 * Convert a Date into a YYYYMMDD number.
 */
export const getNumber = (input: Date): number => Number(format(input, 'yyyyMMdd'));

/**
 * Convert a Date into a "yyyy-MM-dd" string or a specified format.
 */
export const getString = (input: Date, dateFormat: string = "yyyy-MM-dd"): string => format(input, dateFormat);

/**
 * Return an object of start/end predefined date of Last Week.
 */
export const getLastWeek = (): TimeframeObjectType => ({
	enabled: true,
	end:     getNumber(getSunday(0)),
	start:   getNumber(getMonday(1)),
});

/**
 * Return an object of start/end predefined date of Two Weeks Ago.
 */
export const getTwoWeeksAgo = (): TimeframeObjectType => ({
	enabled: true,
	end:     getNumber(getSunday(1)),
	start:   getNumber(getMonday(2)),
});

/**
 * Check to see if a given timeframe is Last Week.
 */
export const isLastWeek = ({end, start}: TimeframeObjectType): boolean =>
{
	const lastWeek = getLastWeek();
	return start === lastWeek.start && end === lastWeek.end;
}

/**
 * Check to see if a given timeframe is Last Week.
 */
export const isThisWeek = ({end, start}: TimeframeObjectType): boolean =>
{
	const thisWeek = getThisWeek();
	return start === thisWeek.start && end === thisWeek.end;
}

/**
 * Check to see the date matches a predefined time frame
 */
export const isPredefined = (input: TimeframeObjectType): string =>
{
	let response = NONE;
	
	// Only if the date is enabled
	if (input.enabled)
	{
		response = CUSTOM;
		
		// If the selected date matches last week
		if (isLastWeek(input))
		{
			response = LAST_WEEK;
		}
		
		// If the selected date matches this week
		else if (isThisWeek(input))
		{
			response = THIS_WEEK;
		}
	}
	
	return response;
}
/**
 * Return an object of start/end predefined date of This Week.
 */
export const getThisWeek  = (): TimeframeObjectType => ({
	enabled: true,
	end:     getNumber(getSunday(-1)),
	start:   getNumber(getMonday(0)),
});

/**
 * Retrieve the closest last Monday relative to the input or today.
 * 0 weeksAgo - Tuesday (10th), return Monday (8th)
 * 1 weeksAgo - Tuesday (10th), return Monday (1st)
 */
export const getMonday = (weeksAgo: number = 0, input ?: Date): Date =>
{
	let date = input ? input : getToday();
	
	if (!isMonday(date))
	{
		// Get the ISO Day of week (Monday - 1 to Sunday - 7)
		const isoDay = getISODay(date);
		
		// Move back to Monday
		date = dateSub(date, isoDay - 1);
	}
	
	// Move it back a given number of weeks
	date = dateSub(date, 7 * weeksAgo);
	
	return date;
}

/**
 * Retrieve the closest last Sunday relative to the input or today.
 * 0 weeksAgo - Tuesday (17th), return Sunday (14th)
 * 1 weeksAgo - Tuesday (17th), return Monday (7th)
 */
export const getSunday = (weeksAgo: number = 0, input ?: Date): Date =>
{
	let date = input ? input : getToday();
	
	if (!isSunday(date))
	{
		// Get the ISO Day of week (Monday - 1 to Sunday - 7)
		const isoDay = getISODay(date);
		
		// Move back to Monday
		date = dateSub(date, isoDay);
	}
	
	// Move it back a given number of weeks
	date = dateSub(date, 7 * weeksAgo);
	
	return date;
}

/**
 * Return the maximum date (up to Yesterday) as a formatted string.
 */
export const getMaximumEnd = (input: number, dateFormat: string): string =>
{
	const yesterday = getYesterday();
	const parsed    = parseNumber(input);
	const max       = isAfter(parsed, yesterday) ? yesterday : parsed;
	
	return format(max, dateFormat);
}

/**
 * Ensure the date string provided is in YYYY-MM-DD format and is a valid date.
 */
export const isDateValid = (input: string): boolean =>
{
	const cleanInput  = input.trim();
	const parsedInput = parseISO(cleanInput)
	
	if (isValid(parsedInput))
	{
		const matches = cleanInput.match(/^([0-9]{4}-[0-9]{2}-[0-9]{2})$/);
		return matches.length ? true : false;
	}
	
	return false;
}

/**
 * Convert a number 1000 seconds into HH:MM:SS
 * https://stackoverflow.com/a/1322771
 */
export const getDurationHours = (seconds: number): string =>
{
	let response = '00:00:00';
	
	if (seconds > 0)
	{
		const duration = intervalToDuration({
			start: 0,
			end:   seconds * 1000,
		});
		
		const output = {
			hours:   duration.hours < 10 ? '0' + duration.hours : duration.hours,
			minutes: duration.minutes < 10 ? '0' + duration.minutes : duration.minutes,
			seconds: duration.seconds < 10 ? '0' + duration.seconds : duration.seconds,
		}
		
		response = `${output.hours}:${output.minutes}:${output.seconds}`;
	}
	
	return response;
}

/**
 * Convert a number 1000 seconds into MM:SS
 * https://stackoverflow.com/a/1322771
 */
export const getDurationMinutes = (seconds: number): string =>
{
	let response = '00:00';
	
	if (seconds > 0)
	{
		const duration = intervalToDuration({
			start: 0,
			end:   seconds * 1000,
		});
		
		const output = {
			minutes: duration.minutes < 10 ? '0' + duration.minutes : duration.minutes,
			seconds: duration.seconds < 10 ? '0' + duration.seconds : duration.seconds,
		}
		
		response = `${output.minutes}:${output.seconds}`;
	}
	
	return response;
}

