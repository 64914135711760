import { CURRENCY_EUR, CURRENCY_GBP, CURRENCY_USD, NUMBER_2DP, NUMBER_2DP_THOUSANDS, NUMBER_SIMPLIFIED, NUMBER_THOUSANDS, PERCENT, PERCENT_DIVIDE_100 } from '@/helpers/enum';
import isNumber                                                                                                                                         from 'lodash/isNumber';
import isString                                                                                                                                         from 'lodash/isString';
import isUndefined                                                                                                                                      from 'lodash/isUndefined';
import numbro                                                                                                                                           from 'numbro';

/**
 * Return a string based of a number using Numbro.
 * http://numbrojs.com/format.html#format
 */
export const formatNumber = (input: any, format: string): string =>
{
	let numberInstance = getNumber(input);
	
	let options = {
		average:           false,
		mantissa:          0,
		spaceSeparated:    false,
		postfix:           '',
		prefix:            '',
		thousandSeparated: false,
	};
	
	if (PERCENT_DIVIDE_100 === format)
	{
		numberInstance = numberInstance / 100;
	}
	
	switch (format)
	{
		case CURRENCY_GBP:
			options.mantissa          = 2;
			options.prefix            = '£';
			options.thousandSeparated = true;
			break;
		
		case CURRENCY_EUR:
			options.mantissa          = 2;
			options.prefix            = '€';
			options.thousandSeparated = true;
			break;
		
		case CURRENCY_USD:
			options.mantissa          = 2;
			options.prefix            = '$';
			options.thousandSeparated = true;
			break;
		
		case NUMBER_2DP:
			options.mantissa = 2;
			break;
		
		case NUMBER_2DP_THOUSANDS:
			options.mantissa          = 2;
			options.thousandSeparated = true;
			break;
		
		case NUMBER_SIMPLIFIED:
			options.average        = true;
			options.mantissa       = 2;
			options.spaceSeparated = true;
			break;
		
		case NUMBER_THOUSANDS:
			options.thousandSeparated = true;
			break;
		
		case PERCENT:
		case PERCENT_DIVIDE_100:
			options.mantissa          = 2;
			options.postfix           = '%';
			options.thousandSeparated = true;
			break;
	}
	
	return numbro(numberInstance)
		.format(options);
}

/**
 * Determine whether an input is a currency.
 */
export const isCurrency = (input: any): boolean =>
{
	if (isString(input) || isNumber(input))
	{
		const stringInstance = isString(input) ? input : input.toString();
		
		const matches = stringInstance.match(/(?=.)^(\S|[A-Z]{3})?\ ?(([1-9][0-9]{0,2}(,[0-9]{3})*)|0)?(\.[0-9]{1,2})?\ ?(\S|[A-Z]{3})?$/);
		
		return null !== matches;
	}
	
	return false;
}

/**
 * Determine whether an input is a percentage.
 */
export const isPercentage = (input: any): boolean =>
{
	if (isString(input))
	{
		const matches = input.match(/^[0-9]{1,2}((,|\.)[0-9]{1,10})?%?$/);
		return null !== matches;
	}
	
	return isNumber(input);
}

/**
 * Formula to work out the percentage increase/decrease.
 */
export const getPercentageIncrease = (newValue: any, oldValue: any): number =>
{
	const newValueNumber = getNumber(newValue);
	const oldValueNumber = getNumber(oldValue);
	
	if (0 === newValueNumber)
	{
		return 0;
	}
	
	return (newValueNumber - oldValueNumber) / newValueNumber * 100;
}

/**
 * Get the percentage of two numbers.
 */
export const getPercentage = (value: any, divisor: any): number =>
{
	const cleanValue   = getNumber(value);
	const cleanDivisor = getNumber(divisor);
	
	if (0 === cleanDivisor)
	{
		return 0;
	}
	
	return cleanValue / cleanDivisor;
}

/**
 * Take an input and return a number.
 */
export const getNumber = (input: any): number =>
{
	let number;
	
	// Already a number
	if (isNumber(input))
	{
		number = input;
	}
	
	// Null should be a 0
	else if (null === input)
	{
		number = 0;
	}
	
	// Decode number if it's a string
	else if (isString(input))
	{
		number = numbro.unformat(input);
	}
	
	// Convert currency or percentage into number
	else if (isCurrency(input) || isPercentage(input))
	{
		number = numbro.unformat(input);
		
		if (isUndefined(number))
		{
			const match = RegExp(/^\D*([0-9\.]*)\D*$/, 'g')
				.exec(input);
			
			if (match.length)
			{
				number = Number(match[1]);
			}
		}
	}
	
	return number;
}
