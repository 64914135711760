import { Box as ChakraBox, useStyleConfig } from "@chakra-ui/react"
import { ReactNode } from 'react';

export type BoxType = {
	children?: ReactNode,
	variant?: string,
	[chakraOptions: string]: any,
}

const Box = (props: BoxType) =>
{
	const {children, variant, ...rest} = props;
	
	// Prepare the values
	const styles = useStyleConfig("CustomBox", {variant});
	
	return (
		<ChakraBox sx={styles}
		           {...rest}
		>
			{children}
		</ChakraBox>
	);
};

Box.defaultProps = {
	children:  undefined,
	variant:   '',
};

export default Box;
