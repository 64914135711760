import { getColor } from '@/helpers/theme';

export const COPY_THEME = {
	baseStyle: ({colorMode}) => ({
		color: getColor(colorMode, 'white', ['background', 'base']),
	}),
	variants:  {
		'primaryCta':   ({colorMode}) => ({
			color: getColor(colorMode, ['background', 'base'], 'white'),
		}),
		'secondaryCta': ({colorMode}) => ({
			color: getColor(colorMode, 'white', ['background', 'base']),
		}),
	},
};

export const HEADING_THEME = {
	baseStyle: {},
}
