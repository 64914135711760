import { CONFIG } from '@/app/app';
import { AWS, DEVELOPMENT, EnvironmentType, PRODUCTION, STAGING, VERCEL } from '@/helpers/enum';
import isUndefined from 'lodash/isUndefined';

export const isProduction  = (): boolean => PRODUCTION === getEnvironment();
export const isStaging     = (): boolean => STAGING === getEnvironment();
export const isDevelopment = (): boolean => DEVELOPMENT === getEnvironment();

/**
 * Determine the environment we're using.
 */
export const getEnvironment = (): EnvironmentType =>
{
	let platformEnvironment = process.env.ENVIRONMENT;
	
	if (isVercel())
	{
		platformEnvironment = process.env.NEXT_PUBLIC_VERCEL_ENV;
	}
	
	switch (platformEnvironment)
	{
		case 'development':
			return DEVELOPMENT;
		
		case 'preview':
		case 'staging':
			return STAGING;
		
		default:
			return PRODUCTION;
	}
}

/**
 * Check to see if we are working on AWS Lambda.
 */
export const isAws = (): boolean => AWS === CONFIG.platform;

/**
 * Check to see if we are working on Vercel.
 */
export const isVercel = (): boolean => VERCEL === CONFIG.platform;

/**
 * Check to see if we are developing locally or on the hosted platform.
 */
export const isLocalhost = (): boolean =>
{
	if (isAws())
	{
		return 'true' === process.env['AWS_SAM_LOCAL'];
	}
	else if (isVercel())
	{
		return isUndefined(process.env['AWS_EXECUTION_ENV']);
	}
	
	return false;
}
