import theme from '@/app/scss/theme.module.scss';
import TEXT_STYLES from '@/app/textStyles';
import ALERT_THEME from '@/components/Alert/theme';
import BOX_THEME from '@/components/Box/theme';
import BREADCRUMB_THEME from '@/components/Breadcrumbs/theme';
import BUTTON_THEME from '@/components/Button/theme';
import { METRIC_THEME } from '@/components/Chart/theme';
import { DROPDOWN_MULTIPLE_THEME, DROPDOWN_SINGLE_THEME } from '@/components/Dropdown/theme';
import { CHECKBOX_THEME, INPUT_THEME } from '@/components/Form/theme';
import HORIZONTAL_RULE_THEME from '@/components/HorizontalRule/theme';
import LINK_THEME from '@/components/Link/theme';
import MODAL_DASHBOARD_THEME from '@/components/Modal/theme';
import { NAVIGATION_SIDE_THEME } from '@/components/Navigation/theme';
import TOOLTIP_THEME from '@/components/Tooltip/theme';
import { COPY_THEME, HEADING_THEME } from '@/components/Typography/theme';
import { CHAKRA_BREAKPOINTS } from '@/helpers/breakpoints';
import { getColor, resetComponent } from '@/helpers/theme';
import { theme as ChakraTheme } from '@chakra-ui/react';
import merge from 'lodash/merge';

/**
 * https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/components
 */
const COMPONENTS = {
	Alert:      resetComponent('Alert', ALERT_THEME),
	Button:     resetComponent('Button', BUTTON_THEME),
	Breadcrumb: resetComponent('Breadcrumb', BREADCRUMB_THEME),
	Checkbox:   resetComponent('Checkbox', CHECKBOX_THEME),
	CustomBox:  resetComponent('CustomBox', BOX_THEME),
	Divider:    resetComponent('Divider', HORIZONTAL_RULE_THEME),
	Drawer:     resetComponent('Drawer', NAVIGATION_SIDE_THEME),
	Heading:    resetComponent('Heading', HEADING_THEME),
	Input:      resetComponent('Input', INPUT_THEME),
	Link:       resetComponent('Link', LINK_THEME),
	Menu:       resetComponent('Menu', {
		baseStyle: {
			list: {
				borderRadius: 0,
				zIndex:       10,
			},
		},
		variants:  {
			...DROPDOWN_MULTIPLE_THEME,
			...DROPDOWN_SINGLE_THEME,
		},
	}),
	Modal:      resetComponent('Modal', MODAL_DASHBOARD_THEME),
	Stat:       resetComponent('Stat', METRIC_THEME),
	Text:       resetComponent('Text', COPY_THEME),
	Tooltip:    resetComponent('Tooltip', TOOLTIP_THEME),
}

export const CHART_COLOURS = {
	light: [
		theme['correct-light-base'],
		theme['alert-light-base'],
		theme['warning-light-base'],
		theme['additional-light-v0'],
		theme['additional-light-v1'],
		theme['additional-light-v2'],
		theme['additional-light-v3'],
		theme['additional-light-v4'],
		theme['background-light-v0'],
		theme['background-light-v0'],
		theme['background-light-v0'],
		theme['background-light-v0'],
		theme['background-light-v0'],
		theme['background-light-v0'],
	],
	dark:  [
		theme['correct-dark-base'],
		theme['alert-dark-base'],
		theme['warning-dark-base'],
		theme['additional-dark-v0'],
		theme['additional-dark-v1'],
		theme['additional-dark-v2'],
		theme['additional-dark-v3'],
		theme['additional-dark-v4'],
		theme['background-dark-v0'],
		theme['background-dark-v0'],
		theme['background-dark-v0'],
		theme['background-dark-v0'],
		theme['background-dark-v0'],
		theme['background-dark-v0'],
	],
};

export const COLORS = {
	alert:      {
		dark:  {
			base: theme['alert-dark-base'],
			80:   theme['alert-dark-v80'],
		},
		light: {
			base: theme['alert-light-base'],
			80:   theme['alert-light-v80'],
		},
	},
	correct:    {
		dark:  {
			base: theme['correct-dark-base'],
			80:   theme['correct-dark-v80'],
		},
		light: {
			base: theme['correct-light-base'],
			80:   theme['correct-light-v80'],
		},
	},
	background: {
		dark:  {
			base: theme['background-dark-base'],
			80:   theme['background-dark-v80'],
			60:   theme['background-dark-v60'],
			40:   theme['background-dark-v40'],
			20:   theme['background-dark-v20'],
			5:    theme['background-dark-v5'],
			0:    theme['background-dark-v0'],
		},
		light: {
			base: theme['background-light-base'],
			80:   theme['background-light-v80'],
			60:   theme['background-light-v60'],
			40:   theme['background-light-v40'],
			20:   theme['background-light-v20'],
			5:    theme['background-light-v5'],
			0:    theme['background-light-v0'],
		},
	},
	warning:    {
		dark:  {
			base: theme['warning-dark-base'],
			80:   theme['warning-dark-v80'],
		},
		light: {
			base: theme['warning-light-base'],
			80:   theme['warning-light-v80'],
		},
	},
	additional: {
		dark:  {
			0: theme['additional-dark-v0'],
			1: theme['additional-dark-v1'],
			2: theme['additional-dark-v2'],
			3: theme['additional-dark-v3'],
			4: theme['additional-dark-v4'],
		},
		light: {
			0: theme['additional-light-v0'],
			1: theme['additional-light-v1'],
			2: theme['additional-light-v2'],
			3: theme['additional-light-v3'],
			4: theme['additional-light-v4'],
		},
	},
	overlay:    {
		background: theme['overlay-background'],
	},
	white:      theme['white'],
};

// Shorthand functions to return a color based on the color mode
export const getBackgroundColor = colorMode => getColor(colorMode, ['background', 'base'], 'white');
export const getTextColor       = colorMode => getColor(colorMode, 'white', ['background', 'base']);

// Maximum widths for the container at given breakpoints
export const CONTAINER_MAX_WIDTHS = ['100%', '540px', '720px', '960px', '1140px', '1400px'];

const CUSTOM_THEME = {
	...ChakraTheme,
	breakpoints:        CHAKRA_BREAKPOINTS,
	colors:             COLORS,
	components:         merge(ChakraTheme.components, COMPONENTS),
	config:             {
		initialColorMode: "dark",
	},
	fonts:              {
		heading: "Raleway",
		body:    "Montserrat",
	},
	sizes:              {},
	styles:             {
		global: ({colorMode}) => ({
			body: {
				background: getBackgroundColor(colorMode),
				color:      getTextColor(colorMode),
			},
		}),
	},
	textStyles:         TEXT_STYLES,
	useSystemColorMode: false,
}

export default CUSTOM_THEME;
