import { DASHBOARD_NAME }                  from '@/app/app';
import '@/app/styles.scss';
import Theme                               from '@/app/theme';
import { sendPageView }                    from '@/helpers/libs/google/analytics';
import LayoutApp                           from '@/layouts/_App';
import store                               from '@/redux/store';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { Global }                          from "@emotion/react"
import type { AppLayoutProps }             from 'next/app'
import Head                                from 'next/head';
import { useRouter }                       from 'next/router'
import { useEffect }                       from 'react';
import { Provider as ReactReduxProvider }  from 'react-redux'
import { SWRConfig }                       from 'swr';

const Fonts = () => (
	<Global styles={`
		@font-face
		{
		font-family:  'TescoBank';
		src:          url('/fonts/TESCOModern-Light-final.woff');
		font-style:   normal;
		font-weight:  300;
		font-display: swap;
		}
		
		@font-face
		{
		font-family:  'TescoBank';
		src:          url('/fonts/TESCOModern-Regular-final.woff');
		font-style:   normal;
		font-weight:  400;
		font-display: swap;
		}
		
		@font-face
		{
		font-family:  'TescoBank';
		src:          url('/fonts/TESCOModern-Medium-final.woff');
		font-style:   normal;
		font-weight:  500;
		font-display: swap;
		}
		
		@font-face
		{
		font-family:  'TescoBank';
		src:          url('/fonts/TESCOModern-Bold.woff');
		font-style:   normal;
		font-weight:  700;
		font-display: swap;
		}
      `}
	/>
)

const MyApp = ({Component, pageProps}: AppLayoutProps) =>
{
	const router    = useRouter();
	const getLayout = Component.getLayout || (page => <LayoutApp children={page}/>);
	
	/**
	 * Google Analytics page tracking
	 * https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js
	 */
	useEffect(() =>
	{
		const handleRouteChange = url => sendPageView(url);
		router.events.on('routeChangeComplete', handleRouteChange)
		return () => router.events.off('routeChangeComplete', handleRouteChange);
	}, [router.events])
	
	/**
	 * Styled Components Fix
	 * On load - Remove the server-side injected CSS.
	 */
	/**
	useEffect(() =>
	{
		const jssStyles = document.querySelector('#jss-server-side');
		if (jssStyles)
		{jssStyles.parentElement.removeChild(jssStyles);}
	}, []);
	 */
	
	return (
		<SWRConfig value={{revalidateOnFocus: false, shouldRetryOnError: false}}>
			<ReactReduxProvider store={store}>
				<ChakraProvider theme={Theme}>
					<Head>
						<title>{DASHBOARD_NAME}</title>
						<meta name='robots'
						      content='noindex, nofollow'/>
						<meta name='viewport'
						      content='minimum-scale=1, initial-scale=1, width=device-width'/>
						{/* Global Site Tag (gtag.js) - Google Analytics */}
						<script async
						        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
						/>
						<script dangerouslySetInnerHTML={
							{
								__html: `
								window.dataLayer = window.dataLayer || [];
								function gtag(){dataLayer.push(arguments);}
								gtag('js', new Date());
								gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
								  page_path: window.location.pathname,
								});
								`,
							}
						}/>
					</Head>
                    <Fonts/>
					{/** @ts-ignore //todo: Need to resolve typescript error */}
					<ColorModeScript initialColorMode={Theme.config.initialColorMode}/>
					{getLayout(<Component {...pageProps}/>)}
		        </ChakraProvider>
			</ReactReduxProvider>
		</SWRConfig>
	);
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

// MyApp.propTypes = {
// 	Component: PropTypes.elementType.isRequired,
// 	pageProps: PropTypes.object.isRequired,
// };


export default MyApp;
