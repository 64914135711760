import { getColor } from '@/helpers/theme';

export const THEME = {
	baseStyle: ({colorMode}) => ({
		link: {
			color:          getColor(colorMode, 'white', ['background', 'base']),
			padding:        '0.25rem',
			textDecoration: "underline",
			textStyle:      'navigationPrimary',
			_focus:         {
				outline: "revert",
			},
			_hover:         {
				bg:             getColor(colorMode, 'white', ['background', 'base']),
				color:          getColor(colorMode, ['background', 'base'], 'white'),
				textDecoration: "underline",
			},
		},
	}),
};

export default THEME;
