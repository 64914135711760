import { getColor } from '@/helpers/theme';

const primaryButton = (colorMode, type) => ({
	bg:           getColor(colorMode, 'white', ['background', 'base']),
	borderRadius: 0,
	color:        getColor(colorMode, ['background', 'base'], 'white'),
	height:       '3rem',
	minWidth:     'dropdown' === type ? '150px' : 'auto',
	position:     'relative',
	px:           4,
	textAlign:    'dropdown' === type ? 'left' : 'inherit',
	textStyle:    'primaryCta',
	_after:       {
		bg:         getColor(colorMode, ['background', '40'], ['background', '80']),
		content:    '""',
		height:     '0',
		left:       '50%',
		opacity:    '1',
		position:   'absolute',
		top:        '50%',
		transform:  'translate(-50%, -50%)',
		transition: '0.3s',
		width:      '0',
	},
	_focus:       {
		boxShadow: "none",
		outline:   "revert",
	},
	_hover:       {
		bg:     getColor(colorMode, ['background', '40'], ['background', '80']),
		color:  getColor(colorMode, ['background', 'base'], 'white'),
		_after: {
			height:  '100%',
			opacity: '0',
			width:   '100%',
		},
	},
});

export const THEME = {
	baseStyle: {
		minW: 10,
	},
	variants:  {
		primary:         ({colorMode}) => primaryButton(colorMode, 'standard'),
		primaryDropdown: ({colorMode}) => primaryButton(colorMode, 'dropdown'),
		secondary:       ({colorMode}) => ({
			bg:           getColor(colorMode, ['background', 'base'], 'white'),
			borderRadius: 0,
			border:       '2px solid',
			borderColor:  getColor(colorMode, 'white', ['background', 'base']),
			color:        getColor(colorMode, 'white', ['background', 'base']),
			height:       '3rem',
			minWidth:     '80px',
			textStyle:    'secondaryCta',
			_after:       {
				bg:         getColor(colorMode, 'white', ['background', 'base']),
				content:    '""',
				top:        '50%',
				left:       "50%",
				width:      '0',
				height:     '0',
				position:   'absolute',
				transform:  'translate(-50%, -50%)',
				transition: '0.3s',
				opacity:    '1',
			},
			_focus:       {
				boxShadow: "none",
				outline:   "revert",
			},
			_hover:       {
				bg:     getColor(colorMode, ['background', 'base'], 'white'),
				_after: {
					height:  'calc(100% - 4px)',
					opacity: '1',
					width:   'calc(100% - 4px)',
				},
			},
		}),
		navigation:      ({colorMode}) => ({
			bg:             'transparent',
			borderRadius:   0,
			color:          getColor(colorMode, 'white', ['background', 'base']),
			padding:        '0.25rem',
			textDecoration: 'underline',
			textStyle:      'navigationPrimary',
			_focus:         {
				boxShadow: "none",
				outline:   "revert",
			},
			_hover:         {
				bg:    getColor(colorMode, 'white', ['background', 'base']),
				color: getColor(colorMode, ['background', 'base'], 'white'),
			},
		}),
	},
};

export default THEME;
