import { CONTAINER_MAX_WIDTHS } from '@/app/theme';
import { Box } from '@chakra-ui/react';
import isUndefined from 'lodash/isUndefined';
import { ReactNode } from 'react';

export type ContainerType = {
	children?: ReactNode,
	isFluid?: boolean,
	maxWidths?: string | string[],
	[chakraOptions: string]: any,
}

const Container = (props: ContainerType) =>
{
	const {children, isFluid, maxWidths, ...rest} = props;
	
	// Default max width is from the App Theme
	let maxWidth: string | string[] = CONTAINER_MAX_WIDTHS;
	
	// Fluid
	if (isFluid && isUndefined(maxWidths))
	{
		maxWidth = '100%';
	}
	
	// Specified max widths
	else if (!isUndefined(maxWidths))
	{
		maxWidth = maxWidths;
	}
	
	return (
		<Box maxWidth={maxWidth}
		     mx='auto'
		     px='0.75rem'
		     width='100%'
		     {...rest}
		>
			{children}
		</Box>
	);
}

Container.defaultProps = {
	children:  undefined,
	isFluid:   false,
	maxWidths: undefined,
}

export default Container;

