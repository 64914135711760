import { Column, Container, Row } from '@/components/Bootstrap';
import Box                        from '@/components/Box/Box';
import Link                       from '@/components/Link/Link';
import Copy                       from '@/components/Typography/Copy';
import { Flex, useColorMode }     from "@chakra-ui/react"
import Image                      from 'next/image';

const Footer = () =>
{
	const {colorMode}   = useColorMode();
	const tescoBankLogo = 'dark' === colorMode ? '/logos/tescobank_white.svg' : '/logos/tescobank_color.svg';
	const emsLogo       = 'dark' === colorMode ? '/logos/8ms_horizontal_white.svg' : '/logos/8ms_horizontal_black.svg';
	
	return (
		<>
			<Box variant='footerOuter'>
				<Container>
					{/**<Row>
						<Column>
							<Flex justify='center'>
								<Link href='/terms'>Terms & Conditions</Link>
							</Flex>
						</Column>
					</Row>*/}
					<Row>
						<Column>
							<Flex justify='center'>
								<Box overrides={{marginRight: '2rem'}}>
									<Image src={tescoBankLogo}
									       height={28}
									       width={163}/>
								</Box>
								<Box>
									<Image src={emsLogo}
									       height={22}
									       width={227}/>
								</Box>
							</Flex>
						</Column>
					</Row>
				</Container>
			</Box>
			<Box variant='copyrightOuter'>
				<Container>
					<Row>
						<Column>
							<Copy textStyle='captionLabel'>All rights reserved {new Date().getFullYear()} &copy; 8 Million Stories</Copy>
						</Column>
					</Row>
				</Container>
			</Box>
		</>
	);
}

export default Footer;
