import { getColor } from '@/helpers/theme';

const THEME = {
	baseStyle: ({colorMode}) => ({
		color:          getColor(colorMode, 'white', ['background', 'base']),
		mx:             '0.5rem',
		padding:        '0.25rem',
		textDecoration: "underline",
		_focus:         {
			outline: "revert",
		},
		_hover:         {
			bg:             getColor(colorMode, 'white', ['background', 'base']),
			color:          getColor(colorMode, ['background', 'base'], 'white'),
			textDecoration: "underline",
		},
	}),
};


export default THEME;
