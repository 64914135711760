import { COLORS } from '@/app/theme';
import { defaultTo } from '@/helpers/format';
import { theme as ChakraTheme } from "@chakra-ui/react";
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import merge from 'lodash/merge';

/**
 * Color mode must be passed through as it needs to be from a React component.
 * Dark input always used and must be provided
 */
export const getColor = (colorMode: 'dark' | 'light', dark: string | string[], light: string | string[] = ''): string =>
{
	// Sub function to find the color
	const findKey = (keys: string[]): string =>
	{
		let response: string;
		let ref: object = COLORS;
		
		for (let i = 0; i < keys.length; i++)
		{
			// Key exists
			if (!isUndefined(ref[keys[i]]))
			{
				ref = ref[keys[i]];
				
				// Color Mode key exists
				if (!isUndefined(ref[colorMode]))
				{
					ref = ref[colorMode];
				}
			}
			
			// String
			else if (isString(ref))
			{
				// @ts-ignore
				response = ref;
			}
		}
		
		// String
		if (isString(ref))
		{
			// @ts-ignore
			response = ref;
		}
		
		return response;
	}
	
	const getKeys = (input: string | string[]): string[] => isString(input) ? [<string>input] : <string[]>input;
	
	let keys = [];
	
	// Dark mode
	if ('dark' === colorMode)
	{
		keys = getKeys(dark);
	}
	
	// Light mode
	else
	{
		// No Light value passed in, use same as Dark
		keys = '' === light ? getKeys(dark) : getKeys(light);
	}
	
	// Use sub function to get the key
	const response = findKey(keys);
	
	return response;
}

/**
 * Remove all the styles from Chakra UI.
 */
export const resetComponent = (component: string, styles: object): object =>
{
	let theme = ChakraTheme.components[component];
	
	// Custom themes don't exist
	if (isUndefined(theme))
	{
		theme = {
			baseStyle:    {},
			defaultProps: {},
			sizes:        {},
			variants:     {},
		};
	}
	
	// Some components don't have a base style
	if (theme && isUndefined(theme['baseStyle']))
	{
		theme.baseStyle = {};
	}
	
	// Function provided (process first as Functions are always Objects apparently)
	if (isFunction(styles['baseStyle']))
	{
		theme.baseStyle = styles['baseStyle'];
	}
	
	// Object provided
	else if (isObject(styles['baseStyle']))
	{
		if (isEmpty(styles['baseStyle']))
		{
			theme.baseStyle = {};
		}
		else
		{
			theme.baseStyle = merge(theme.baseStyle, styles['baseStyle']);
		}
	}
	
	theme.defaultProps = defaultTo({}, styles, 'defaultProps');
	theme.sizes        = defaultTo({}, styles, 'sizes');
	theme.variants     = defaultTo({}, styles, 'variants');
	
	return theme;
}
