/**
 * Function to help simplifying alignments by passing a single value.
 */
export const buildAlignment = align =>
{
	let response = '';
	
	if (align)
	{
		switch (align)
		{
			case 'start':
			case 'end':
				response = `flex-${align}`;
				break;
			
			case 'around':
			case 'evenly':
			case 'between':
				response = `space-${align}`;
				break;
			
			default:
				response = align;
		}
	}
	
	return response;
}
