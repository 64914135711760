import { FAILED, INFORMATION, SUCCESS, WARNING } from '@/helpers/enum';
import { getColor }                              from '@/helpers/theme';

const THEME = {
	baseStyle: {
		description: {
			lineHeight: 'revert',
		},
	},
	variants:  {
		[FAILED]:      ({colorMode}) => ({
			container: {
				bg:    getColor(colorMode, ['alert', 'base']),
				color: getColor(colorMode, 'white'),
			},
		}),
		[INFORMATION]: ({colorMode}) => ({
			container: {
				bg:    getColor(colorMode, ['additional', '0']),
				color: getColor(colorMode, ['background', 'base'], 'white'),
			},
		}),
		[SUCCESS]:     ({colorMode}) => ({
			container: {
				bg:    getColor(colorMode, ['correct', 'base']),
				color: getColor(colorMode, ['background', 'base'], 'white'),
			},
		}),
		[WARNING]:     ({colorMode}) => ({
			container: {
				bg:    getColor(colorMode, ['warning', 'base']),
				color: getColor(colorMode, ['background', 'base']),
			},
		}),
	},
};

export default THEME;
