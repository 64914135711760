import { getColor } from '@/helpers/theme';

export const CHECKBOX_THEME = {
	parts:     ["container", "control", "label", "icon"],
	baseStyle: ({colorMode}) => ({
		control: {
			_invalid:    {
				borderColor: getColor(colorMode, ['alert', 'base']),
			},
			border:      "2px solid",
			borderColor: getColor(colorMode, 'white', ['background', 'base']),
			color:       getColor(colorMode, 'white', ['background', 'base']),
			height:      "1rem",
			width:       "1rem",
		},
		icon:    {
			fontSize: "0.6rem",
		},
	}),
}

export const INPUT_THEME = {
	baseStyle: ({colorMode}) => ({
		field: {
			_placeholder: {
				color: getColor(colorMode, ['background', 'base'], 'white'),
			},
			bg:           getColor(colorMode, 'white', ['background', 'base']),
			color:        getColor(colorMode, ['background', 'base'], 'white'),
			padding:      "9px",
			textStyle:    'body',
			width:        "100%",
			minWidth:     0,
			outline:      0,
			position:     "relative",
			appearance:   "none",
			transition:   "all 0.2s",
		},
	}),
}
