import { BreakpointInputType, getPopulatedBreakpoints } from '@/helpers/breakpoints';
import { buildAlignment } from '@/helpers/css';
import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';

export type ColumnType = {
	children?: ReactNode,
	spans?: BreakpointInputType,
	offsets?: BreakpointInputType
	vAlign?: undefined | 'start' | 'center' | 'end',
	[chakraOptions: string]: any,
}

const buildProperty = property =>
{
	let response = [];
	
	if (property)
	{
		const widths = getPopulatedBreakpoints(property);
		
		response = widths.map(width => width / 12 * 100 + '%');
	}
	
	return response;
}

const Column = (props: ColumnType) =>
{
	const {children, offsets, spans, vAlign, ...rest} = props;
	
	return (
		<Box
			alignSelf={buildAlignment(vAlign)}
			marginLeft={buildProperty(offsets)}
			maxWidth='100%'
			px='0.75rem'
			width={buildProperty(spans)}
			{...rest}
		>
		{children}
		</Box>
	);
}

Column.defaultProps = {
	children: undefined,
	spans:    {
		xs: 12,
	},
	offsets:  null,
	vAlign:   undefined,
}

export default Column;
