import { getNumber }                   from '@/helpers/date';
import { URL, URL_DECODE, URL_ENCODE } from '@/helpers/enum';
import isArray                         from 'lodash/isArray';
import isDate                          from 'lodash/isDate';
import isNumber                        from 'lodash/isNumber';
import isObject                        from 'lodash/isObject';
import isString                        from 'lodash/isString';

/**
 * Take an input that may have a / before or after the input and return just the folder.
 */
export const getCleanFolder = (input: string): string => input.replace(/[\/]+/g, '')
	.trim();

/**
 * Get either a string or an array of strings and always return an array of strings.
 */
export const getStringArray = (input: string | string[], delimiter: string = null): string[] =>
{
	// Array
	if (isArray(input))
	{
		return (<string[]>input).map(item => item.trim());
	}
	
	// String
	else if (isString(input))
	{
		if (delimiter && input.indexOf(delimiter) > -1)
		{
			return (<string>input).split(delimiter)
				.map(item => item.trim());
		}
		
		return <string[]>[input];
	}
	
	return [];
}

/**
 * Convert into JSON.
 */
export const getJson = (input: any): string =>
{
	let json;
	
	// Already a string
	if (isString(input))
	{
		json = input;
	}
	
	// Convert a number into a date
	else if (isNumber(input))
	{
		json = input;
	}
	
	// Object or array to JSON
	else if (isObject(input) || isArray(input))
	{
		json = JSON.stringify(input);
	}
	
	// Date
	else if (isDate(input))
	{
		json = getNumber(input)
			.toString();
	}
	
	return json;
}

/**
 * Convert into string.
 */
export const getString = (input: any): string =>
{
	let string = '';
	
	// Already a string
	if (isString(input))
	{
		string = input;
	}
	
	// Convert a number into a date
	else if (isNumber(input))
	{
		string = input.toString();
	}
	
	// Object or array to JSON
	else if (isObject(input) || isArray(input))
	{
		string = JSON.stringify(input);
	}
	
	// Date
	else if (isDate(input))
	{
		string = getNumber(input)
			.toString();
	}
	
	return string;
}

/**
 * Return a URL.
 */
export const formatString = (input: any, format: string): string =>
{
	let stringInstance = getString(input);
	
	switch (format)
	{
		case URL:
			return stringInstance;
		
		case URL_DECODE:
			return decodeURI(stringInstance);
		
		case URL_ENCODE:
			return encodeURI(stringInstance);
		
		default:
			return stringInstance;
	}
}

/**
 * Return a string "With Proper Casing".
 */
export const toProperCase = (input: any): string =>
{
	const preparedInput = getString(input);
	
	const clean = preparedInput.split('_')
		.join(' ');
	
	return clean.replace(/\w\S*/g, word => word.charAt(0)
		.toUpperCase() + word.substr(1)
		.toLowerCase());
}

/**
 * Return a string without any tab characters, replaced by default with a space.
 */
export const removeTabs = (input: string, replace: string = ' '): string => input.replace(/(\t)/gm, replace);

/**
 * Return a string without any new lines, replaced by default with a space.
 */
export const removeNewLines = (input: string, replace: string = ' '): string => input.replace(/(\r\n|\n|\r)/gm, replace);

/**
 * Shorthand to check if a string contains a substring.
 */
export const contains = (needle: any, haystack: any): boolean => haystack.indexOf(needle) > -1;

/**
 * Converts a stream into a string.
 * https://stackoverflow.com/a/49428486
 */
export const streamToString = (stream): Promise<string> =>
{
	const chunks = [];
	return new Promise((resolve, reject) =>
	{
		stream.on('data', (chunk) => chunks.push(Buffer.from(chunk)));
		stream.on('error', (err) => reject(err));
		stream.on('end', () => resolve(Buffer.concat(chunks)
			.toString('utf8')));
	})
}
