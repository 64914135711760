import { getColor } from '@/helpers/theme';

const THEME = {
	baseStyle: {
		border:            'none',
		borderBottomWidth: '0',
		height:            '1px',
		opacity:           1,
	},
	variants:  {
		gradient:     ({colorMode}) => ({
			bgGradient: 'dark' === colorMode ? "linear(to right, white, transparent)" : "linear(to right, background.light.base, transparent)",
		}),
		gradientCard: ({colorMode}) => ({
			bgGradient:   'dark' === colorMode ? "linear(to right, white, transparent)" : "linear(to right, background.light.base, transparent)",
			marginBottom: '1rem',
			marginLeft:   '-1rem',
			marginTop:    '1rem',
		}),
		solid:        ({colorMode}) => ({
			bg: getColor(colorMode, ['background', '80']),
		}),
	},
};


export default THEME;
