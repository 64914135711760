import { isProduction }                       from '@/helpers/environment';
import authSlice, { AuthState }               from '@/redux/slices/auth';
import healthCheckSlice, { HealthCheckState } from '@/redux/slices/healthCheck';
import { configureStore }                     from '@reduxjs/toolkit';

export interface RootState
{
	auth: AuthState,
	healthCheck: HealthCheckState,
}

const store = configureStore({
	devTools: !isProduction(),
	reducer:  {
		auth:        authSlice,
		healthCheck: healthCheckSlice,
	},
});

export default store;
