import { getColor } from '@/helpers/theme';

export const METRIC_THEME = {
	baseStyle: ({colorMode}) => ({
		filledTrack: {
			bg: getColor(colorMode, ['additional', '0'], ['additional', '1']),
		},
		track:       {
			bg:     getColor(colorMode, ['background', '60']),
			height: '10px',
			width:  '100%',
		},
	}),
};

export const PROGRESS_THEME = {
	baseStyle: ({colorMode}) => ({
		filledTrack: {
			bg: getColor(colorMode, ['additional', '0']),
		},
		track:       {
			bg:     getColor(colorMode, ['background', '60']),
			height: '4px',
			width:  '100%',
		},
	}),
	variants:  {
		negative: ({colorMode}) => ({
			filledTrack: {
				bg: getColor(colorMode, ['alert', 'base']),
			},
		}),
		positive: ({colorMode}) => ({
			filledTrack: {
				bg: getColor(colorMode, ['correct', 'base']),
			},
		}),
	},
};
