import { CONFIG } from '@/app/app';
import { AjaxType, FAILED, IDLE, PENDING, SUCCESS } from '@/helpers/enum';
import { post }                                     from '@/helpers/libs/axios';
import { UserClient, UserRole }                     from '@prisma/client';
import { createAsyncThunk, createSlice }            from '@reduxjs/toolkit';
import isEmpty                                      from 'lodash/isEmpty';
import { destroyCookie }                            from 'nookies';

export const defaultAuthUser: AuthUser = {
	client:    null,
	email:     '',
	firstName: '',
	id:        0,
	isActive:  false,
	lastName:  '',
	role:      undefined,
}

export type AuthUser = {
	client: UserClient,
	email: string,
	firstName: string,
	id: number,
	isActive: boolean,
	lastName: string,
	role: UserRole,
}

export interface AuthState
{
	ajaxStatus: AjaxType,
	status: typeof FAILED | typeof SUCCESS,
	user: AuthUser,
}

const initialState: AuthState = {
	ajaxStatus: IDLE,
	status:     FAILED,
	user:       {
		...defaultAuthUser,
	},
};

/**
 * Get the user's logged in status onload
 */
export const checkSession = createAsyncThunk('auth/checkSession', async () =>
{
	const payload = {
		user:   defaultAuthUser,
		status: FAILED,
	};
	
	await post({
		onError:   response => {},
		onSuccess: response =>
		           {
			           payload.user   = response.data;
			           payload.status = isEmpty(response.data) ? FAILED : SUCCESS;
		           },
		url:       '/api/auth/checkCookie',
	});
	
	return payload;
});

/**
 * Log out and set the active session token to inactive
 */
export const logOut = createAsyncThunk('auth/logOut', async () =>
{
	await post({
		onError:   response => {},
		onSuccess: response => {},
		url:       '/api/auth/logOut',
	});
});

const authSlice = createSlice(
	{
		name:          'auth',
		initialState,
		reducers:      {
			logIn(state, action)
			{
				state.ajaxStatus = SUCCESS;
				state.user       = {
					...action.payload.user,
				};
				state.status     = SUCCESS;
			},
		},
		extraReducers: builder =>
		               {
			               builder.addCase(checkSession.pending, (state) =>
				               {
					               state.ajaxStatus = PENDING;
				               },
			               ),
				               builder.addCase(checkSession.fulfilled, (state, action) =>
					               {
						               state.ajaxStatus = SUCCESS;
						               state.user       = {
							               ...action.payload.user,
						               };
						
						               // @ts-ignore
						               state.status = action.payload.status;
					               },
				               ),
				               builder.addCase(checkSession.rejected, (state, action) =>
					               {
						               state.ajaxStatus = FAILED;
						               state.status     = FAILED;
					               },
				               ),
				               builder.addCase(logOut.pending, (state) =>
					               {
						               state.ajaxStatus = PENDING;
					               },
				               ),
				               builder.addCase(logOut.fulfilled, (state, action) =>
					               {
						               destroyCookie(null, CONFIG.cookies.auth, {path: '/'});
						
						               state.ajaxStatus = SUCCESS;
						               state.user       = {
							               ...defaultAuthUser,
						               };
						               state.status     = FAILED;
					               },
				               ),
				               builder.addCase(logOut.rejected, (state, action) =>
					               {
						               state.ajaxStatus = FAILED;
						               state.status     = FAILED;
					               },
				               )
		               },
		
	},
);

export const {logIn} = authSlice.actions;

export default authSlice.reducer;


