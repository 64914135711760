import { getColor } from '@/helpers/theme';

const THEME = {
	baseStyle: ({colorMode}) => ({
		"--tooltip-bg":      getColor(colorMode, 'white', ['background', 'base']),
		px:                  "0.5rem",
		py:                  "0.5rem",
		bg:                  "var(--tooltip-bg)",
		"--popper-arrow-bg": "var(--tooltip-bg)",
		color:               getColor(colorMode, ['background', 'base'], 'white'),
		boxShadow:           "md",
		maxW:                "200px",
		zIndex:              "tooltip",
	}),
};

export default THEME;
