import { TextStyleType }           from '@/app/textStyles';
import { getPopulatedBreakpoints } from '@/helpers/breakpoints';
import { getColor }                from '@/helpers/theme';
import { Text }                    from "@chakra-ui/react"
import { ReactNode }               from 'react';

type CopyType = TextStyleType & {
	children?: ReactNode,
	overrides?: object,
	tag?: 'p' | 'span',
	textStyle?: string,
	truncate?: boolean,
	variant: 'body' | 'caption' | 'lead' | 'primaryCta' | 'secondaryCta',
}

/**
const Copy = ({children, overrides, tag, textStyle, truncate, variant}: CopyType) =>
{
	return (
		<_Copy overrides={overrides}
		       tag={tag}
		       textStyle={textStyle}
		       truncate={truncate}
		       variant={variant}
		>
			{children}
		</_Copy>
	);
}

Copy.defaultProps = _Copy.defaultProps;

export default Copy;





export type _CopyType = {
	children?: ReactNode,
	overrides?: object,
	tag?: 'p' | 'span',
	textStyle?: string,
	truncate?: boolean,
	variant?: string,
}
 */

const Copy = ({children, overrides, tag, textStyle, truncate, variant}: CopyType) =>
{
	return (
		<Text as={tag}
		      isTruncated={truncate}
		      textStyle={textStyle}
		      variant={variant}
		      {...overrides}
		>
			{children}
		</Text>
	);
}

Copy.defaultProps = {
	children:  undefined,
	overrides: {},
	tag:       'p',
	textStyle: 'body',
	truncate:  false,
	variant:   '',
}

export default Copy;
