import { DOMAINS as GENERAL_MILLS_DOMAINS } from '@/app/generalmills';
import { DOMAINS as TESCO_BANK_DOMAINS } from '@/app/tescobank';
import { FAILED, IDLE, PENDING, SUCCESS } from '@/helpers/enum';
import { post } from '@/helpers/libs/axios';
import { RootState } from '@/redux/store';
import { UserClient } from '@prisma/client';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export interface HealthCheckState
{
	ajaxStatus: typeof IDLE | typeof PENDING | typeof SUCCESS | typeof FAILED,
	crawls: {
		[key: string]: string[],
	},
	clients: {
		id: string,
		label: string
	}[],
	client: {
		id: string,
		label: string,
	},
	current: number,
	comparison: number,
	error: any,
	status: typeof FAILED | typeof SUCCESS,
}

const initialState: HealthCheckState = {
	ajaxStatus: IDLE,
	crawls:     {},
	client:     {
		id:    "tescobank_com",
		label: "Tescobank.com",
	},
	clients:    [
		{
			id:    "tescobank_com",
			label: "Tescobank.com",
		},
	],
	current:    null,
	comparison: null,
	error:      null,
	status:     FAILED,
};

/**
 * Get the user's logged in status onload
 */
export const init = createAsyncThunk('healthCheck/init', async (params, thunkAPI) =>
{
	let clientId: string = '';
	let payload: object  = {};
	
	// Make API request
	await post({
		onSuccess: response =>
		           {
			           if (200 === response.status)
			           {
				           payload = {
					           crawls:     response.data,
					           current:    parseInt(response.data["tescobank_com"][0]),
					           comparison: parseInt(response.data["tescobank_com"].length > 1 ? response.data["tescobank_com"][1] : response.data.crawls["tescobank_com"][0]),
				           };
			           }
		           },
		url:       '/api/aws/athena/getCrawls',
	});
	
	return payload;
});

const healthCheckSlice = createSlice(
	{
		name:          'healthCheck',
		initialState,
		reducers:      {
			
			/**
			 * Updating a client involves updating all 3 parameters.
			 */
			updateClient(state, action)
			{
				state.client.id    = action.payload.client.id;
				state.client.label = action.payload.client.label;
				state.comparison   = action.payload.comparison;
				state.current      = action.payload.current;
			},
			
			updateCrawls(state, action)
			{
				state.crawls = action.payload.crawls;
			},
			
			updateDefault(state, action)
			{
				state.crawls     = action.payload.crawls;
				state.comparison = action.payload.comparison;
				state.current    = action.payload.current;
			},
			
			updateAfterGetCrawls(state, action)
			{
				state.crawls       = action.payload.crawls;
				state.clients      = action.payload.clients;
				state.client.id    = action.payload.client.id;
				state.client.label = action.payload.client.label;
				state.comparison   = action.payload.comparison;
				state.current      = action.payload.current;
			},
			
			/**
			 * Update the comparison time frame.
			 */
			updateComparison(state, action)
			{
				state.comparison = action.payload;
			},
			
			/**
			 * Update the current time frame.
			 */
			updateCurrent(state, action)
			{
				state.current = action.payload;
			},
		},
		extraReducers: builder =>
		               {
			               builder.addCase(init.pending, (state: HealthCheckState) =>
				               {
					               state.ajaxStatus = PENDING;
				               },
			               ),
				               builder.addCase(init.fulfilled, (state: HealthCheckState, action) =>
					               {
						               state.ajaxStatus = SUCCESS;
						
						               // @ts-ignore
						               state.crawls = action.payload.crawls;
						
						               // @ts-ignore
						               //state.clients = action.payload.clients;
						
						               // @ts-ignore
						               //state.client.id = action.payload.client.id;
						
						               // @ts-ignore
						               //state.client.label = action.payload.client.label;
						
						               // @ts-ignore
						               state.current = action.payload.current;
						
						               // @ts-ignore
						               state.comparison = action.payload.comparison;
						               state.status     = SUCCESS;
					               },
				               ),
				               builder.addCase(init.rejected, (state: HealthCheckState, action) =>
					               {
						               state.ajaxStatus = FAILED;
						               state.status     = FAILED;
						               state.error      = action.payload;
					               },
				               )
		               },
	},
);

export const {updateClient, updateAfterGetCrawls, updateCrawls, updateComparison, updateCurrent, updateDefault} = healthCheckSlice.actions;

export default healthCheckSlice.reducer;


