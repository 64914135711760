import isBoolean from 'lodash/isBoolean';

/**
 * Convert an input from a variety of forms into boolean
 */
export const getBoolean = (input: any): boolean =>
{
	if (isBoolean(input))
	{
		return <boolean>input;
	}
	
	return 'true' === input || 1 === input || '1' === input || 'yes' === input;
}
