import { Footer } from '@/components/Footer';

const _App = ({children}) =>
{
	return (
		<>
			{children}
			<Footer/>
		</>
	);
}

export const getLayout = page => <_App>{page}</_App>;

export default _App;
