import { AWS, VERCEL } from '@/helpers/enum';

export type ConfigType = {
	athena: {
		athenaExpressS3: string,
		database: string,
	},
	charts: {
		font: string,
	},
	cookies: {
		[cookieType: string]: string,
	},
	email: string,
	dashboard: {
		home: string,
		id: string,
		name: string,
	},
	googleAnalytics: string,
	platform: typeof AWS | typeof VERCEL,
	s3: {
		// S3 Bucket used for caching API requests
		cache: string,
		// Standard non-versioned S3 Bucket
		standard: string,
		// Versioned bucket
		versioned: string,
	},
	url: {
		development: string,
		staging: string,
		production: string,
	},
}

// Config used in both AWS SAM and Vercel
export const CONFIG: ConfigType = {
	athena:          {
		athenaExpressS3: 's3://tescobank-dashboard-standard/athena-express',
		database:        'tescobank_dashboard',
	},
	charts:          {
		font: 'Montserrat',
	},
	cookies:         {
		auth:        'authCookie',
		maintenance: 'maintenance',
	},
	dashboard:       {
		home: '/crawl/overview',
		id:   'TescoBankDashboard',
		name: 'Tesco Bank Dashboard',
	},
	email:           `BBCWS Dashboard <development@8ms.com>`,
	googleAnalytics: 'UA-39894094-9',
	platform:        VERCEL,
	s3:              {
		cache:     'tescobank-dashboard-standard',
		standard:  'tescobank-dashboard-standard',
		versioned: 'tescobank-dashboard-versioned',
	},
	url:             {
		development: 'http://tescobank-dashboard.local:8080',
		staging:     'https://staging.tescobank-dashboard.8ms.com',
		production:  'https://tescobank-dashboard.8ms.com',
	},
}

// User access levels
export const ACCESS_ALL: string    = 'all';
export const ACCESS_USERS: string  = 'users';
export const ACCESS_ADMINS: string = 'admins';



// Name used for the authorisation cookie
export const AUTH_COOKIE_NAME: string = 'authCookie';

// Redirect on log in success
export const DASHBOARD_HOME: string = '/crawl/overview';

// The id of the dashboard (used in securing passwords during transmission)
export const DASHBOARD_ID: string = 'TescoBankDashboard';

// The name of the dashboard
export const DASHBOARD_NAME: string = 'Tesco Bank Dashboard';

// The sender of emails
export const EMAIL_SENDER: string = `${DASHBOARD_NAME} <development@8ms.com>`;

// Define the URLs for localhost and vanity production
export const DEVELOPMENT_URL: string = 'http://tescobank-dashboard.local:8080';
export const STAGING_URL: string     = 'https://staging.tescobank-dashboard.8ms.com';
export const PRODUCTION_URL: string  = 'https://tescobank-dashboard.8ms.com';

// The font to use in the charts
export const CHART_FONT: string = 'TescoBank';

// Cookie used to bypass maintenance mode
export const MAINTENANCE_COOKIE_NAME: string = 'maintenance';
