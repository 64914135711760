// Standard
export const NONE: string   = 'none';
export const CUSTOM: string = 'custom';

// Directions
export const ASCENDING  = 'ascending';
export const DESCENDING = 'descending';
export type DirectionType = typeof ASCENDING | typeof DESCENDING;

// Data Type
export const DIMENSION: string = 'dimension';
export const METRIC: string    = 'metric';
export type DimensionMetricType = typeof DIMENSION | typeof METRIC;

// Time frames
export const CURRENT: string    = 'current';
export const COMPARISON: string = 'comparison';
export type TimeframeType = typeof CURRENT | typeof COMPARISON;

// Predefined time frame
export const THIS_WEEK: string = 'this_week';
export const LAST_WEEK: string = 'last_week';
export type PredefinedTimeframeType = typeof THIS_WEEK | typeof LAST_WEEK | typeof NONE | typeof CUSTOM;

// Formats - Boolean
export const BOOLEAN: string = 'boolean';

// Formats - Number
export const CURRENCY_EUR: string         = 'currency_eur';
export const CURRENCY_GBP: string         = 'currency_gbp';
export const CURRENCY_USD: string         = 'currency_usd';
export const NUMBER: string               = 'number';
export const NUMBER_2DP: string           = 'number_2dp';
export const NUMBER_2DP_THOUSANDS: string = 'number_2dp_thousands';
export const NUMBER_SIMPLIFIED: string    = 'number_simplified';
export const NUMBER_THOUSANDS: string     = 'number_thousands';
export const PERCENT: string              = 'percent';
export const PERCENT_DIVIDE_100: string   = 'percent_divide_100';
export const PERCENT_NUMBER: string       = 'percent_number';

// Formats - String
export const STRING: string           = 'string';
export const HTML: string             = 'html';
export const JSON_FORMAT: string      = 'json';
export const SQL: string              = 'sql';
export const URL: string              = 'url';
export const URL_DECODE: string       = 'url_decode';
export const URL_ENCODE: string       = 'url_encode';
export const STRING_FORMATS: string[] = [HTML, JSON_FORMAT, STRING, SQL, URL, URL_DECODE, URL_ENCODE];

// Statuses - Ajax
export const IDLE: string        = 'idle';
export const INFORMATION: string = 'info';
export const PENDING: string     = 'pending';
export const SUCCESS: string     = 'success';
export const FAILED: string      = 'failed';
export const WARNING: string     = 'warning';
export type AjaxType = typeof IDLE | typeof PENDING | typeof SUCCESS | typeof FAILED | typeof INFORMATION | typeof WARNING;

// Statuses - Active/Inactive
export const ACTIVE: string   = 'active';
export const INACTIVE: string = 'inactive';
export type ActiveType = typeof IDLE | typeof ACTIVE | typeof INACTIVE;

// Durations
export const FOREVER: string = 'forever';
export const MONTHS: string  = 'months';
export const MINUTES: string = 'minutes';
export const HOURS: string   = 'hours';
export const DAYS: string    = 'days';

// Duration Comparisons
export const VS_NOW: string      = 'vsNow';
export const VS_MIDNIGHT: string = 'vsMidnight';

// Used in dropdowns
export const ALL: string = 'all';

// Environments
export const PRODUCTION: string  = 'production';
export const STAGING: string     = 'staging';
export const DEVELOPMENT: string = 'development';
export type EnvironmentType = typeof DEVELOPMENT | typeof STAGING | typeof PRODUCTION;

// Platforms
export const AWS: string    = 'aws';
export const VERCEL: string = 'vercel';
export type PlatformType = typeof AWS | typeof VERCEL;
