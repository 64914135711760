import { getColor } from '@/helpers/theme';

/**
 * Different to standard theme as this is combination of Multiple and Single Dropdown.
 */
export const DROPDOWN_SINGLE_THEME = {
	primary:    ({colorMode}) => ({
		item: {
			_focus: {
				outline: 'revert',
			},
		},
		list: {
			bg:           getColor(colorMode, 'white', ['background', 'base']),
			borderRadius: 0,
			color:        getColor(colorMode, ['background', 'base'], 'white'),
			float:        'left',
			maxHeight:    '200px',
			minWidth:     '150px',
			overflowY:    'scroll',
			overflowX:    'hidden',
			px:           "0.25rem",
			py:           "0.4rem",
			zIndex:       10,
		},
	}),
	navigation: ({colorMode}) => ({
		item: {
			_focus: {
				outline: 'revert',
			},
		},
		list: {
			bg:       getColor(colorMode, ['background', 'base'], 'white'),
			color:    getColor(colorMode, 'white', ['background', 'base']),
			minWidth: '150px',
			overflow: 'hidden',
			px:       "0.8rem",
			py:       "0.4rem",
			zIndex:   10,
		},
	}),
};


/**
 * Different to standard theme as this is combination of Multiple and Single Dropdown.
 */
export const DROPDOWN_MULTIPLE_THEME = {
	primaryDropdownMultiple: ({colorMode}) => ({
		list: {
			bg:       getColor(colorMode, 'white', ['background', 'base']),
			color:    getColor(colorMode, ['background', 'base'], 'white'),
			minWidth: '150px',
			px:       "0.8rem",
			py:       "0.4rem",
		},
	}),
};

